$(function() {
    var $newsletterBlock = $('.container > .columns > aside .sidebar-block--newsletter');
    if($newsletterBlock.length) {
        var $newsletterForm = $newsletterBlock.find('form');
        var $newsletterInput = $newsletterBlock.find('input[type=email]');
        var $newsletterButton = $newsletterBlock.find('button[type=submit]');
        var $responseMessage = $newsletterBlock.find('.response');

        // Fade in / out submit button
        $newsletterInput.on('keyup', function() {
            var disabled = $newsletterInput.val() === '' ? true : false;
            $newsletterButton.attr('disabled', disabled);
        });

        // Deal with request
        $newsletterForm.on('submit', function(e) {
            e.preventDefault();
            var emailAddress = $newsletterInput.val();

            var url = $('html').data('theme-url');
            url += '/ajax/newsletter.php';

            $newsletterForm.addClass('loading');

            $.ajax({
                method: "POST",
                url: url,
                data: { email: $newsletterInput.val() }
            }).done(function(response) {
                var response = JSON.parse(response);
                if(response.success) {
                    $responseMessage.html('Thanks for signing up!').removeClass('error').addClass('success');
                    $responseMessage.slideDown();
                    $newsletterInput.val('');
                    hideResponseMessage();
                } else {
                    $responseMessage.html(response.details).removeClass('success').addClass('error');
                    $responseMessage.slideDown();
                    hideResponseMessage();
                }
            }).fail(function(response, textStatus, errorThrown) {
                $responseMessage.html('Sorry, something went wrong').removeClass('success').addClass('error');
                $responseMessage.slideDown();
                hideResponseMessage();
            }).always(function(response) {
                $newsletterForm.removeClass('loading');
            });
        });
    }

    function hideResponseMessage() {
        setTimeout(function() {
            $responseMessage.slideUp();
        }, 7000);
    }
});